var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"h-full flex flex-col justify-center group relative lg:max-w-[300px]"},[_c(_vm.actualComponent,{tag:"component",staticClass:"flex flex-row gap-2 items-center py-2 max-lg:px-2 h-full cursor-pointer text-typo-primary hover:text-primary",class:{
      'bg-primary hover:bg-primary-darker rounded-md !text-white px-2':
        _vm.type === 'primary',
    },attrs:{"href":_vm.to},on:{"click":function($event){$event.preventDefault();return _vm.onClick.apply(null, arguments)}}},[(_vm.icon)?_c('IconComponent',{staticClass:"w-6",class:{ 'animate-spin': _vm.icon === 'spinner' },attrs:{"icon":_vm.icon,"pack":_vm.iconPack}}):_vm._e(),(_vm.image)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"border-solid border-2 border-primary rounded-full inline-block w-6 h-6 align-middle",attrs:{"data-src":_vm.image},on:{"error":_vm.$helpers.noImage}}):_vm._e(),(_vm.text || _vm.$slots.default)?_c('span',{staticClass:"truncate flex-1"},[(_vm.text)?[_vm._v(_vm._s(_vm.text))]:[_vm._t("default")]],2):_vm._e(),(_vm.items.length)?_c('IconComponent',{staticClass:"w-6",attrs:{"icon":_vm.isDropdownOpen ? 'angle-up' : 'angle-down'},on:{"click":function($event){_vm.isDropdownOpen = !_vm.isDropdownOpen}}}):_vm._e()],1),(_vm.items.length)?_c('ul',{staticClass:"hidden lg:group-hover:flex flex-col flex-1 px-2 lg:absolute top-full max-lg:w-full bg-white lg:rounded-md lg:z-10 lg:border lg:border-gray-300 lg:shadow-md",class:{
      'max-lg:flex': _vm.isDropdownOpen,
      'right-0': _vm.isLast,
      'left-0': !_vm.isLast,
    }},_vm._l((_vm.items),function({ click, ...props },index){return _c('NavBarItem',_vm._g(_vm._b({key:index},'NavBarItem',props,false),click ? { click } : {}))}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }