var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'JoszakiToast',
    'flex',
    'justify-center',
    'items-center',
    '!px-2',
    'sm:!px-4',
    '!py-1',
    'sm:!py-3',
    'rounded-md',
    '',
    'text-white',
    {
      'bg-info': _vm.type === 'info',
      'bg-success': _vm.type === 'success',
      'bg-warning': _vm.type === 'warning',
      'bg-danger': _vm.type === 'danger',
      'bg-error': _vm.type === 'error',
    },
  ]},[_c('IconComponent',{staticClass:"mr-2 sm:mr-4 !text-base sm:!text-xl min-w-[20px]",attrs:{"icon":_vm.icon}}),_vm._v(" "+_vm._s(_vm.message)+" ")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }