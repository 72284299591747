<template>
  <li
    class="h-full flex flex-col justify-center group relative lg:max-w-[300px]"
  >
    <component
      :is="actualComponent"
      :href="to"
      class="flex flex-row gap-2 items-center py-2 max-lg:px-2 h-full cursor-pointer text-typo-primary hover:text-primary"
      :class="{
        'bg-primary hover:bg-primary-darker rounded-md !text-white px-2':
          type === 'primary',
      }"
      @click.prevent="onClick"
    >
      <IconComponent
        v-if="icon"
        :icon="icon"
        :pack="iconPack"
        class="w-6"
        :class="{ 'animate-spin': icon === 'spinner' }"
      />
      <img
        v-if="image"
        class="border-solid border-2 border-primary rounded-full inline-block w-6 h-6 align-middle"
        v-lazy-load :data-src="image"
        @error="$helpers.noImage"
      />
      <span v-if="text || $slots.default" class="truncate flex-1">
        <template v-if="text">{{ text }}</template>
        <template v-else>
          <slot />
        </template>
      </span>
      <IconComponent
        v-if="items.length"
        :icon="isDropdownOpen ? 'angle-up' : 'angle-down'"
        class="w-6"
        @click="isDropdownOpen = !isDropdownOpen"
      />
    </component>
    <ul
      v-if="items.length"
      class="hidden lg:group-hover:flex flex-col flex-1 px-2 lg:absolute top-full max-lg:w-full bg-white lg:rounded-md lg:z-10 lg:border lg:border-gray-300 lg:shadow-md"
      :class="{
        'max-lg:flex': isDropdownOpen,
        'right-0': isLast,
        'left-0': !isLast,
      }"
    >
      <NavBarItem
        v-for="({ click, ...props }, index) in items"
        :key="index"
        v-bind="props"
        v-on="click ? { click } : {}"
      />
    </ul>
  </li>
</template>

<script>
import { ref, useContext, useRouter } from "@nuxtjs/composition-api";
import IconComponent from "~/components/IconComponent.vue";

export default {
  name: "NavBarItem",
  components: { IconComponent },
  props: {
    id: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconPack: {
      type: String,
      default: "fas",
    },
    image: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "default",
    },
    text: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    openByDefault: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { $trackers } = useContext();
    const isDropdownOpen = ref(props.openByDefault);
    const onClick = () => {
      if (props.id) {
        $trackers.trackClick("NavBarItem", { itemId: props.id });
      }
      if (props.to) {
        router.push(props.to);
      }

      if (props.items.length) {
        isDropdownOpen.value = !isDropdownOpen.value;
      }

      emit("click");
    };

    const actualComponent = props.to ? "a" : "span";
    return {
      onClick,
      isDropdownOpen,
      actualComponent,
    };
  },
};
</script>
