export function generatePreloadLinks(items) {
  return items.map((item) => {
    let as;
    let type;
    let href;

    switch (typeof item) {
      // If strings are provided, use them as href, and default to fonts
      case "string":
        as = "font";
        type = "font/woff2";
        href = item;
        break;

      // If objects are provided, use them as specified
      case "object":
        as = item.as;
        type = item.type;
        href = item.href;

        if (!as || !type || !href) {
          throw new Error(
            "Preload link generation failed: if an object is provided, it must specify `as`, `type`, and `href`."
          );
        }
        break;
    }

    return { rel: "preload", as, type, href, crossorigin: "anonymous" };
  });
}
