<template>
  <transition name="zoom">
    <div
      v-if="confirmOpen"
      class="fixed z-[5000] top-0 left-0 w-full h-full flex items-center justify-center isolate overscroll-contain"
    >
      <transition name="fade">
        <div
          class="JoszakiConfirmBackDrop absolute top-0 left-0 w-full h-full bg-transparent z-0 backdrop-blur-md"
          @click="close"
        />
      </transition>
      <div
        class="JoszakiConfirmWindow z-10 w-full max-w-xl bg-white rounded-md shadow-md border border-gray-300 max-h-[90vh] overflow-y-auto flex flex-col relative isolate p-4 gap-4"
      >
        <JoszakiHeading :level="2">
          {{ title ?? $t("confirmDialog.title") }}
        </JoszakiHeading>
        <div class="flex items-center gap-4">
          <div class="text-4xl" :class="{ [`text-${type}`]: type }">
            <IconComponent :icon="icon" />
          </div>
          <span
            v-dangerouslySetInnerHTML="message ?? $t('confirmDialog.message')"
          />
        </div>
        <div class="flex items-center justify-between gap-4">
          <JoszakiButton @click="close">
            {{ $t("common.cancel") }}
          </JoszakiButton>
          <JoszakiButton type="danger" @click="confirm">
            {{ confirmText ?? $t("common.accept") }}
          </JoszakiButton>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { JOSZAKI_CONFIRM_EVENT } from "~/plugins/joszaki";

export default {
  data() {
    return {
      confirmOpen: false,
      message: "asdadasdasasdasdas",
      confirmText: null,
      onConfirm: null,
      onCancel: null,
      title: null,
      type: "danger",
    };
  },
  computed: {
    icon() {
      switch (this.type) {
        case "info":
          return "question-circle";
        default:
          return "exclamation-triangle";
      }
    },
  },
  mounted() {
    this.$mitt.on(JOSZAKI_CONFIRM_EVENT, this.show);
  },
  beforeDestroy() {
    this.$mitt.off(JOSZAKI_CONFIRM_EVENT, this.show);
  },
  methods: {
    show({ message, confirmText, onConfirm, onCancel, title, type }) {
      if (!onConfirm) {
        throw new Error("missing onConfirm callback");
      }
      this.confirmOpen = true;
      this.message = message;
      this.title = title;
      this.confirmText = confirmText;
      this.onConfirm = onConfirm;
      this.onCancel = onCancel;
      this.type = type ?? "danger";
    },
    confirm() {
      this.onConfirm();
      this.close();
    },
    close() {
      if (this.onCancel) {
        this.onCancel();
      }
      this.confirmOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.zoom-enter-active,
.zoom-leave-active {
  transition: all 0.2s ease;
}
.zoom-enter,
.zoom-leave-to {
  transform: scale(0, 0);
}
</style>
